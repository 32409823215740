import React, { useEffect } from 'react'
import { TextInput, SelectInput, useRecordContext } from 'react-admin'
import { useFormContext } from 'react-hook-form' // Import useFormContext from react-hook-form
import UniversitySelect from './UniversitySelect' // Importing the UniversitySelect component

const UserForm = ({
  selectedUniversity,
  setSelectedUniversity,
  defaultUniversityName,
  states,
  cities,
  selectedState,
  setSelectedState,
  selectedCity,
  setSelectedCity
}) => {
  const record = useRecordContext()
  const { setValue } = useFormContext() // Use useFormContext to set form field values

  const currentYear = new Date().getFullYear()
  const graduationYears = Array.from({ length: 11 }, (_, i) => ({
    id: currentYear + i,
    name: (currentYear + i).toString()
  }))

  // Update the selectedUniversity and defaultUniversityName based on the record
  useEffect(() => {
    if (record?.profile) {
      setSelectedUniversity(record.profile.universityId || '')
      setSelectedState(record.profile.university.stateCode || '')
      setSelectedCity(record.profile.city || '')
    }
  }, [record, setSelectedUniversity, setSelectedState, setSelectedCity])

  // Whenever selectedUniversity changes, update the universityId in the form
  useEffect(() => {
    setValue('profile.universityId', selectedUniversity) // Update the universityId in the form state
  }, [selectedUniversity, setValue])

  return (
    <>
      {/* Profile fields */}
      <TextInput
        source='profile.fullName'
        label='Full Name'
        defaultValue={record?.profile?.fullName || ''}
      />
      <TextInput source='profile.email' label='Email' defaultValue={record?.profile?.email || ''} />

      {/* University selection */}
      <UniversitySelect
        selectedUniversity={selectedUniversity}
        setSelectedUniversity={setSelectedUniversity}
        defaultUniversityName={record?.profile?.university?.name || 'Select a university'}
      />

      {/* Graduation dropdown */}
      <SelectInput
        source='profile.graduation'
        label='Graduation'
        defaultValue={record?.profile?.graduation || ''}
        choices={[
          { id: 'Undergraduate', name: 'Undergraduate' },
          { id: 'Postgraduate', name: 'Postgraduate' },
          { id: 'Alumni', name: 'Alumni' },
          { id: 'ProfessorOrLecturer', name: 'ProfessorOrLecturer' },
          { id: 'Staff', name: 'Staff' }
        ]}
      />

      {/* Graduation Year dropdown */}
      <SelectInput
        source='profile.graduationYear'
        label='Graduation Year'
        defaultValue={record?.profile?.graduationYear || ''}
        choices={graduationYears}
      />

      {/* Level dropdown */}
      <SelectInput
        source='profile.level'
        label='Level'
        defaultValue={record?.profile?.level || ''}
        choices={[
          { id: 'Freshmen', name: 'Freshmen' },
          { id: 'Sophomore', name: 'Sophomore' },
          { id: 'Junior', name: 'Junior' },
          { id: 'Senior', name: 'Senior' },
          { id: 'Alumni', name: 'Alumni' },
          { id: 'ProfessorOrLecturer', name: 'ProfessorOrLecturer' },
          { id: 'Staff', name: 'Staff' }
        ]}
      />

      {/* State selection */}
      <SelectInput
        source='profile.university.stateCode'
        label='State'
        value={selectedState}
        choices={states.map(state => ({
          id: state.isoCode,
          name: state.name
        }))}
        helperText={'University State: ' + record.profile.university.stateCode}
        onChange={e => setSelectedState(e.target.value)}
      />

      {/* City selection */}
      <SelectInput
        key={selectedCity}
        source='profile.city'
        label='City'
        value={selectedCity}
        helperText={'Current city of the student: ' + record.profile.city}
        choices={cities.map(city => ({
          id: city.name,
          name: city.name
        }))}
        disabled={!selectedState}
        onChange={e => setSelectedCity(e.target.value)}
      />

      {/* Country code (disabled) */}
      <TextInput source='profile.countryCode' label='Country Code' defaultValue='US' disabled />
    </>
  )
}

export default UserForm
