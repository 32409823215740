// src/components/events/EventShow.tsx
import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ImageField,
  NumberField,
  BooleanField
} from 'react-admin'

// EventShow component to display detailed information of an event
const EventShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='id' label='Event ID' />
      <TextField source='title' label='Title' />
      <TextField source='description' label='Description' />
      <DateField source='startDate' label='Start Date' />
      <DateField source='endDate' label='End Date' />
      <TextField source='status' label='Status' />
      <TextField source='frequency' label='Frequency' />
      <TextField source='meetingType' label='Meeting Type' />
      <TextField source='visibility' label='Visibility' />
      <TextField source='eventType' label='Event Type' />
      <TextField source='meetingDetails' label='Meeting Details' />
      <TextField source='hostedBy' label='Hosted By' />
      <TextField source='calendarEventId' label='Calendar Event ID' />
      <ImageField source='image.url' label='Event Image' />
      <TextField source='creator.fullName' label='Creator Name' />
      <TextField source='creator.email' label='Creator Email' />
      <TextField source='creator.university.name' label='University' />
      <NumberField source='stats.totalMembersCount' label='Total Members' />
      <NumberField source='stats.repliedMembersCount' label='Replied Members' />
      <DateField source='createdAt' label='Created At' />
      <DateField source='updatedAt' label='Updated At' />
    </SimpleShowLayout>
  </Show>
)

export default EventShow
