// src/components/events/EventList.tsx
import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  DateField,
  EditButton,
  ShowButton,
  DeleteButton,
  Filter,
  TextInput,
  SelectInput
} from 'react-admin'

// Define a filter component with more filtering options for events
const EventsFilter = props => (
  <Filter {...props}>
    <TextInput label='Search by Title or ID' source='title' alwaysOn />
  </Filter>
)

// EventList component with additional fields and enhanced filters
const EventList = () => (
  <List filters={<EventsFilter />}>
    <Datagrid rowClick='show'>
      <TextField source='id' label='Event ID' />
      <TextField source='title' label='Title' />
      <DateField source='startDate' label='Start Date' />
      <DateField source='endDate' label='End Date' />
      <TextField source='status' label='Status' />
      <TextField source='meetingType' label='Meeting Type' />
      <TextField source='visibility' label='Visibility' />
      <TextField source='eventType' label='Event Type' />
      <TextField source='hostedBy' label='Hosted By' />
      <ImageField source='image.url' label='Image' />
      <TextField source='creator.fullName' label='Creator' />
      <TextField source='creator.university.name' label='University' />
      <TextField source='stats.totalMembersCount' label='Total Members' />
      <TextField source='stats.repliedMembersCount' label='Replied Members' />
      <ShowButton />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

export default EventList
