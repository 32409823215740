import { Edit, SimpleForm, TextInput, ImageField, ImageInput, SelectInput, BooleanInput } from 'react-admin';

 const ClubEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="name" label="Club Name" />
      <TextInput source="description" label="Description" multiline />
      <SelectInput source="type" label="Club Type" choices={[
        { id: 'Open', name: 'Open' },
        { id: 'Closed', name: 'Closed' },
      ]} />
      <SelectInput source="status" label="Status" choices={[
        { id: 'Active', name: 'Active' },
        { id: 'Inactive', name: 'Inactive' },
      ]} />
      <BooleanInput source="isMemberEventsEnabled" label="Enable Member Events" />
      <ImageInput source="image.url" label="Club Image" >
        <ImageField source="url" />
      </ImageInput>
      <TextInput source="socialMedias[0].url" label="Instagram URL" />
      {
      /* 
      <TextInput source="socialMedias[0].url" label="Instagram URL" />
      Repeat for other social media platforms as needed */}
    </SimpleForm>
  </Edit>
);

export default ClubEdit;