// components/UserList.tsx
import React from 'react'
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  Identifier,
  RaRecord
} from 'react-admin'

const UserList = () => {
  const UsersFilter = props => (
    <Filter {...props}>
      <TextInput label='Search by Name or ID' source='name' alwaysOn />
    </Filter>
  )

  return (
    <List filters={<UsersFilter />}>
      <Datagrid
        rowClick={(id: Identifier, resource: string, record: RaRecord<Identifier>) => {
          return `/users/${record.id}/show`
        }}
      >
        <TextField source='id' label='ID' />
        <TextField source='fullName' label='Full Name' />
        <EmailField source='email' label='Email' />
        <TextField source='university.name' label='University' />
        <TextField source='city' label='City' />
        <DateField source='createdAt' label='Created At' />
      </Datagrid>
    </List>
  )
}

export default UserList
