// src/App.tsx
import React from 'react'
import { Admin, Resource } from 'react-admin'
import authProvider from './providers/authProvider'
import dataProvider from './providers/dataProvider'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import UserList from './resources/users/UserList'
import { UserEdit } from './resources/users/UserEdit'
import { UserCreate } from './resources/users/UserCreate'
import { UserShow } from './resources/users/UserShow'

import ClubsList from './resources/clubs/ClubList'
import ClubCreate from './resources/clubs/ClubCreate'
import ClubEdit from './resources/clubs/ClubEdit'
import ClubShow from './resources/clubs/ClubShow'

import UniversityList from './resources/universities/UniversityList'
import UniversityCreate from './resources/universities/UniversityCreate'
import UniversityEdit from './resources/universities/UniversityEdit'
import UniversityShow from './resources/universities/UniversityShow'

import EventList from './resources/events/EventList'
import EventShow from './resources/events/EventShow'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2'
    },
    secondary: {
      main: '#dc004e'
    }
  }
})

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Admin authProvider={authProvider} dataProvider={dataProvider}>
        <Resource name='profiles' list={UserList} />
        <Resource name='users' edit={UserEdit} create={UserCreate} show={UserShow} />
        <Resource
          name='clubs'
          list={ClubsList}
          edit={ClubEdit}
          create={ClubCreate}
          show={ClubShow}
        />
        <Resource
          name='universities'
          list={UniversityList}
          edit={UniversityEdit}
          create={UniversityCreate}
          show={UniversityShow}
        />
        <Resource name='events' list={EventList} show={EventShow} />

        {/*
        <Resource
          name="clubs"
          list={ClubsList}
          create={AppClubsCreate}
          edit={AppClubsEdit}
          show={AppClubsShow}
        />
        
        <Resource name="universities" list={ClubsList} />
        <Resource name="levels" list={ClubsList} />
        <Resource name="graduations" list={ClubsList} />
        <Resource name="view-waitlist" list={ClubsList} />
        <Resource name="manage-categories" list={ClubsList} />
        <Resource name="manage-clubs" list={ClubsList} />
        <Resource name="pending-universities" list={ClubsList} />
        <Resource name="club-roles" list={ClubsList} />
        <Resource name="club-ownership" list={ClubsList} />
        <Resource name="event-management" list={ClubsList} />
  */}
      </Admin>
    </ThemeProvider>
  )
}

export default App
