// components/UniversityCreate.tsx
import React from 'react';
import { Create, SimpleForm, TextInput, DateInput } from 'react-admin';

const UniversityCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" label="Name" />
      <TextInput source="status" label="Status" />
      <TextInput source="address" label="Address" />
      <TextInput source="stateCode" label="State Code" />
      <TextInput source="email" label="Email" />
      <DateInput source="createdAt" label="Created At" />
      <DateInput source="updatedAt" label="Updated At" />
    </SimpleForm>
  </Create>
);

export default UniversityCreate;
