import React, { useState, useEffect } from 'react'
import { CircularProgress, TextField, Autocomplete, Typography, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useDataProvider } from 'react-admin'

const UniversitySelect = ({ selectedUniversity, setSelectedUniversity, defaultUniversityName }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [universities, setUniversities] = useState([])
  const [loading, setLoading] = useState(false)
  const dataProvider = useDataProvider()
  const { setValue, formState } = useFormContext() // Access form state and setDirty

  const fetchUniversities = (filter = '') => {
    setLoading(true)
    dataProvider
      .getList('universities', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
        filter: { name: filter }
      })
      .then(({ data }) => {
        setUniversities(data.map(university => ({ id: university.id, name: university.name })))
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  useEffect(() => {
    if (searchTerm.length >= 3 || searchTerm === '') {
      fetchUniversities(searchTerm)
    }
  }, [searchTerm])

  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue)
  }

  const handleChange = (event, newValue) => {
    if (newValue) {
      setSelectedUniversity(newValue.id)
    } else {
      setSelectedUniversity('')
    }
  }

  useEffect(() => {
    // Update the universityId in the form state
    setValue('profile.universityId', selectedUniversity, { shouldDirty: true }) // Mark as dirty
  }, [selectedUniversity, setValue])

  const selectedUniversityName =
    universities.find(uni => uni.id === selectedUniversity)?.name || defaultUniversityName

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='h6' gutterBottom flex={'display'}>
        {selectedUniversityName
          ? `Current university is: ${selectedUniversityName}`
          : 'No university selected'}
      </Typography>
      <Autocomplete
        value={universities.find(uni => uni.id === selectedUniversity) || null}
        onChange={handleChange}
        inputValue={searchTerm}
        onInputChange={handleInputChange}
        options={universities}
        getOptionLabel={option => option.name || ''}
        loading={loading}
        clearOnEscape
        freeSolo
        renderInput={params => (
          <TextField
            {...params}
            label='Select a new university'
            variant='outlined'
            fullWidth
            helperText='Enter at least 3 characters to search universities'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </Box>
  )
}

export default UniversitySelect
