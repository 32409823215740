import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  BooleanField,
  EditButton,
  Filter,
  TextInput,
  ShowButton,
  DeleteButton
} from 'react-admin'

const ClubsFilter = props => (
  <Filter {...props}>
    <TextInput label='Search by Name or ID' source='name' alwaysOn />
  </Filter>
)

const ClubList = () => (
  <List filters={<ClubsFilter />}>
    <Datagrid rowClick='edit'>
      <TextField source='name' label='Club Name' />
      <TextField source='type' label='Type' />
      <TextField source='status' label='Status' />
      <BooleanField source='isMemberEventsEnabled' label='Member Events Enabled' />
      <ImageField source='image.url' label='Image' />
      <TextField source='ownerId' label='Owner Id' />
      <ShowButton />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

export default ClubList
