import { Create, SimpleForm, TextInput,ImageField, DateInput, ImageInput, SelectInput } from 'react-admin';

export const UserCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="fullName" label="Full Name" />
      <TextInput source="email" label="Email" />
      <TextInput source="university.name" label="University" />
      <SelectInput source="graduation" choices={[
        { id: 'Undergraduate', name: 'Undergraduate' },
        { id: 'Graduate', name: 'Graduate' },
      ]} />
      <DateInput source="graduationYear" label="Graduation Year" />
      <SelectInput source="level" choices={[
        { id: 'Freshmen', name: 'Freshmen' },
        { id: 'Sophomore', name: 'Sophomore' },
        { id: 'Junior', name: 'Junior' },
        { id: 'Senior', name: 'Senior' },
      ]} />
      <TextInput source="city" label="City" />
      <TextInput source="countryCode" label="Country Code" />
      <ImageInput source="image.url" label="Profile Image">
        <ImageField source="url" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
