import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ImageInput,
  ImageField,
  required,
  minLength,
  maxLength
} from 'react-admin'

export const UniversityEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          source='name'
          label='University Name'
          fullWidth
          validate={[required(), minLength(1), maxLength(300)]}
        />

        <SelectInput
          source='status'
          label='Status'
          choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Pending', name: 'Pending' }
          ]}
          validate={[required()]}
        />

        <TextInput source='description' label='Description' fullWidth multiline />
        <TextInput source='address' label='Address' fullWidth validate={[required()]} />
        <TextInput source='stateCode' label='State Code' fullWidth />
        <TextInput source='website' label='Website' fullWidth validate={[required()]} />
        <TextInput source='email' label='Email' fullWidth validate={[required()]} />
        <TextInput source='emailDomain' label='Email Domain' fullWidth validate={[required()]} />
      </SimpleForm>
    </Edit>
  )
}

export default UniversityEdit
