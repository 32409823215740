// components/UniversityShow.tsx
import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, EmailField } from 'react-admin';

const UniversityShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="name" label="Name" />
      <TextField source="status" label="Status" />
      <TextField source="address" label="Address" />
      <TextField source="stateCode" label="State Code" />
      <EmailField source="email" label="Email" />
      <DateField source="createdAt" label="Created At" />
      <DateField source="updatedAt" label="Updated At" />
    </SimpleShowLayout>
  </Show>
);

export default UniversityShow;
