import { Show, SimpleShowLayout, TextField, ImageField, BooleanField, ArrayField, SingleFieldList, ChipField } from 'react-admin';

 const ClubShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" label="Club Name" />
      <TextField source="description" label="Description" />
      <TextField source="type" label="Club Type" />
      <TextField source="status" label="Status" />
      <BooleanField source="isMemberEventsEnabled" label="Enable Member Events" />
      <ImageField source="image.url" label="Club Image" />
      <ArrayField source="socialMedias" label="Social Media">
        <SingleFieldList>
          <ChipField source="url" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="owner.fullName" label="Owner" />
      <TextField source="owner.university.name" label="University" />
      <TextField source="owner.city" label="City" />
      <TextField source="owner.countryCode" label="Country Code" />
    </SimpleShowLayout>
  </Show>
);

export default ClubShow;