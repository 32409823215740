import {
  DataProvider,
  GetListParams,
  GetOneParams,
  CreateParams,
  UpdateParams,
  DeleteParams
} from 'react-admin'
import axios from 'axios'

const API_URL = 'https://api-v2.icommunify.com/api/admin'

// Configure Axios to always use the token from localStorage
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Function to get the _type for create operations
const getCreateResourceType = (resource: string) => {
  switch (resource) {
    case 'users':
      return 'UserCreate'
    case 'profile':
      return 'ProfileCreate'
    // Add other resources if necessary
    default:
      return ''
  }
}

// Function to get the _type for update operations (recursively updates nested types)
const getUpdateResourceType = (data: any) => {
  if (typeof data === 'object' && data !== null) {
    if (data._type) {
      switch (data._type) {
        case 'Profile':
          data._type = 'ProfileUpdate'
          break
        case 'User':
          data._type = 'UserUpdate'
          break
        case 'University':
          data._type = 'UniversityUpdate'
          break
        // Add more nested _type replacements as needed
        default:
          break
      }
    }

    // Recursively handle nested objects
    Object.keys(data).forEach(key => {
      if (typeof data[key] === 'object' && data[key] !== null) {
        getUpdateResourceType(data[key])
      }
    })
  }
  return data
}

const dataProvider: DataProvider = {
  // Fetch list of resources (e.g., users)
  getList: async (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    // Form sort and filter strings
    const sortQuery = `sort(${field}:${order})`
    const filterQuery = params.filter
      ? Object.keys(params.filter)
          .map(key => `${params.filter[key]}`)
          .join(',')
      : ''

    try {
      const response = await axios.get(`${API_URL}/${resource}`, {
        params: {
          offset: (page - 1) * perPage,
          limit: perPage,
          include: `totalCount,${sortQuery}`,
          filter: filterQuery
        }
      })

      return {
        data: response.data.data,
        total: response.data.totalCount // Make sure this reflects the full count
      }
    } catch (error) {
      console.error('Error fetching list:', error)
      return {
        data: [],
        total: 0
      }
    }
  },

  // Fetch a single resource
  getOne: async (resource: string, params: GetOneParams) => {
    try {
      const response = await axios.get(`${API_URL}/${resource}/${params.id}`)
      return { data: response.data }
    } catch (error) {
      console.error('Error fetching one:', error)
      return { data: {} }
    }
  },

  // Create a new resource
  create: async (resource: string, params: CreateParams) => {
    try {
      // Add the _type dynamically based on the resource
      const data = { ...params.data, _type: getCreateResourceType(resource) }

      const response = await axios.post(`${API_URL}/${resource}`, data)
      return { data: response.data }
    } catch (error) {
      console.error('Error creating resource:', error)
      return { data: {} }
    }
  },

  // Update an existing user with dynamic _type for update (also updates nested types)
  update: async (resource: string, params: UpdateParams) => {
    try {
      // Add the _type dynamically and update nested _type fields
      const data = { ...params.data }
      getUpdateResourceType(data)

      const response = await axios.patch(`${API_URL}/${resource}/${params.id}`, data)
      return { data: response.data }
    } catch (error) {
      console.error('Error updating resource:', error)
      return { data: {} }
    }
  },

  // Delete a resource
  //@ts-ignore
  delete: async (resource: string, params: DeleteParams) => {
    try {
      await axios.delete(`${API_URL}/${resource}/${params.id}`)
      return { data: { id: params.id } }
    } catch (error) {
      console.error('Error deleting resource:', error)
      return { data: { id: params.id } }
    }
  },

  // Placeholder implementations
  getMany: async (resource, params) => Promise.resolve({ data: [] }),
  getManyReference: async (resource, params) => Promise.resolve({ data: [], total: 0 }),
  updateMany: async (resource, params) => Promise.resolve({ data: [] }),
  deleteMany: async (resource, params) => Promise.resolve({ data: [] })
}

export default dataProvider
