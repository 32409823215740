import {
  Edit,
  SimpleForm,
  useRedirect,
  useNotify,
  useDataProvider,
  useRecordContext
} from 'react-admin'
import { useEffect, useState } from 'react'
import { State, City, IState, ICity } from 'country-state-city'
import UserForm from './UserForm'

export const UserEdit = props => {
  const dataProvider = useDataProvider()
  const [states, setStates] = useState<IState[]>([])
  const [cities, setCities] = useState<ICity[]>([])
  const [selectedState, setSelectedState] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [selectedUniversity, setSelectedUniversity] = useState({})
  const [defaultUniversityName, setDefaultUniversityName] = useState('')

  const record = useRecordContext()
  const redirect = useRedirect() // Hook to handle redirection
  const notify = useNotify() // Hook to show notifications

  useEffect(() => {
    const usStates = State.getStatesOfCountry('US')
    setStates(usStates)
  }, [])

  useEffect(() => {
    if (selectedState) {
      const usCities = City.getCitiesOfState('US', selectedState)
      setCities(usCities)
    } else {
      setCities([])
    }
  }, [selectedState])

  useEffect(() => {
    if (record?.profile) {
      setSelectedUniversity(record.profile.university || '')
      setDefaultUniversityName(record.profile.universityName || 'Select a university')
      setSelectedState(record.profile.state || '')
      setSelectedCity(record.profile.city || '')
    }
  }, [record])

  const onSuccess = () => {
    notify(`Changes saved`)
    redirect('/profiles')
  }

  const onError = error => {
    notify(`Error: ${error.message || 'Something went wrong'}`, { type: 'warning' })
  }

  return (
    <Edit
      {...props}
      mutationOptions={{ onSuccess, onError }}
      resource='users'
      mutationMode='pessimistic'
    >
      <SimpleForm>
        <UserForm
          selectedUniversity={selectedUniversity}
          setSelectedUniversity={setSelectedUniversity}
          defaultUniversityName={defaultUniversityName}
          states={states}
          cities={cities}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
        />
      </SimpleForm>
    </Edit>
  )
}
