// src/components/UniversityList.js
import React, { useState } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  ShowButton,
  EditButton,
  DeleteButton,
  useDataProvider,
  Filter,
  TextInput,
  DeleteWithConfirmButton,
  downloadCSV
} from 'react-admin'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material'
import jsonExport from 'jsonexport/dist'

// Define the filter component using the Filter component from react-admin
const UniversityFilter = props => (
  <Filter {...props}>
    <TextInput label='Search by Name or ID' source='name' alwaysOn />
  </Filter>
)

// Simple exporter function
const exporter = async (filters, exportAll, dataProvider) => {
  const perPage = 10000
  const { data } = await dataProvider.getList('universities', {
    pagination: { page: 1, perPage },
    sort: { field: 'id', order: 'ASC' },
    filter: exportAll ? {} : filters
  })

  jsonExport(data, (err, csv) => {
    downloadCSV(csv, `universities${exportAll ? '_all' : ''}`) // Trigger download of CSV
  })
}

const UniversityList = () => {
  const [open, setOpen] = useState(false)
  const [filters, setFilters] = useState({})
  const dataProvider = useDataProvider()

  const handleExport = exportAll => {
    exporter(filters, exportAll, dataProvider)
    setOpen(false)
  }

  const handleOpenExportDialog = currentFilters => {
    setFilters(currentFilters) // Save current filters for use in export
    setOpen(true)
  }

  return (
    <>
      <List filters={<UniversityFilter />} exporter={filters => handleOpenExportDialog(filters)}>
        <Datagrid rowClick='edit'>
          <TextField source='id' label='ID' />
          <TextField source='name' label='Name' />
          <TextField source='website' label='Site' />
          <EmailField source='emailDomain' label='Email Domain' />
          <TextField source='address' label='Address' />
          <TextField source='status' label='Status' />
          <DateField source='createdAt' label='Created At' />
          <ShowButton />
          <EditButton />
          <DeleteWithConfirmButton redirect={false} />
        </Datagrid>
      </List>

      {/* Export Dialog */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Export Options</DialogTitle>
        <DialogContent>
          <DialogContentText>Choose your export option:</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleExport(false)} color='primary'>
            <span>Current with Filters</span>
          </Button>
          <Button onClick={() => handleExport(true)} color='primary'>
            <span>Export All</span>
          </Button>
          <Button onClick={() => setOpen(false)} color='secondary'>
            <span>Cancel</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UniversityList
