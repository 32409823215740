import { Show, SimpleShowLayout, TextField, DateField, ImageField } from 'react-admin'

export const UserShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source='profile.fullName' label='Full Name' />
      <TextField source='profile.email' label='Email' />
      <TextField source='profile.university.name' label='University' />
      <TextField source='profile.graduation' label='Graduation' />
      <TextField source='profile.graduationYear' label='Graduation Year' />
      <TextField source='profile.level' label='Level' />
      <TextField source='profile.city' label='City' />
      <TextField source='profile.countryCode' label='Country Code' />
      <ImageField source='profile.image.url' label='Profile Image' />
    </SimpleShowLayout>
  </Show>
)
